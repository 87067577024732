import PropTypes from 'prop-types';
import React from 'react';

export default function Root({ children }) {
  return (
    <div className="Root" id="root">
      <div className="Root-content">{children}</div>
    </div>
  );
}

Root.propTypes = {
  children: PropTypes.any,
};
