/* eslint-disable react/no-danger */
import React from 'react';
import { Cookies } from 'react-cookie-consent';

// -------------------------------------------------------------

const trackingDebug = process.env.NODE_ENV === 'development';
let trackingConsent = false;
const trackerId = `gtag_${process.env.GATSBY_GA_ID_LOWER}.send`;

// -------------------------------------------------------------

export const trackPageView = (page, skipRetry) => {
  const query = window.location.search ? window.location.search : '';

  if (!trackingConsent) {
    if (trackingDebug)
      console.log(
        'TRACK PAGEVIEW: FAILED, NO TRACKING CONSENT - ',
        page + query
      );
    return;
  }

  if (window.ga) {
    window.ga(trackerId, 'pageview', page + query);
    if (trackingDebug) console.log('TRACK PAGEVIEW: ', page + query);
  } else {
    if (trackingDebug)
      console.log('TRACK PAGEVIEW: FAILED, NO WINDOW.GA - ', page + query);

    if (!skipRetry) {
      if (trackingDebug) console.log('TRACK, RETRYING ... ', page + query);
      setTimeout(() => {
        trackPageView(page, true);
      }, 1000);
    }
  }
};

// -------------------------------------------------------------

export const trackEvent = (area, title) => {
  if (!trackingConsent) {
    if (trackingDebug)
      console.log('TRACK EVENT: FAILED, NO TRACKING CONSENT - ', area, title);
    return;
  }

  if (window.ga) {
    window.ga(trackerId, 'event', area, title);
    if (trackingDebug) console.log('TRACK EVENT: ', area, title);
  } else if (trackingDebug)
    console.log('TRACK EVENT: FAILED, NO WINDOW.GA - ', area, title);
};

// -------------------------------------------------------------

export const checkTrackingConsent = (forceAccept = false) => {
  if (trackingConsent) return;

  if (forceAccept) {
    window[`ga-disable-${process.env.GATSBY_GA_ID}`] = false;
    if (window.fbq) {
      window.fbq('consent', 'grant');
      if (trackingDebug) console.log('TRACKING CONSENT, ALLOW FB PIXEL');
    }
    trackingConsent = true;

    if (window && window.location && window.location.pathname) {
      if (trackingDebug)
        console.log('TRACKING CURRENT PAGE AFTER COOKIE CONSENT');
      trackPageView(window.location.pathname);
    }
  }

  const cookieData = Cookies.get(process.env.GATSBY_COOKIE_NAME);
  if (cookieData && cookieData === 'true') {
    window[`ga-disable-${process.env.GATSBY_GA_ID}`] = false;
    if (window.fbq) window.fbq('consent', 'grant');
    trackingConsent = true;
  }
};

// -------------------------------------------------------------

export const TrackingComponent = (type) => {
  switch (type) {
    case 'ga_disable':
      return (
        <script
          id="ga_disable"
          key="ga_disable"
          dangerouslySetInnerHTML={{
            __html: `window['ga-disable-${process.env.GATSBY_GA_ID}'] = true;`,
          }}
        />
      );
    case 'ga_script':
      return (
        <script
          key="ga_script"
          id="ga_script"
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_ID}`}
        ></script>
      );
    case 'ga_code':
      return (
        <script
          id="ga_code"
          key="ga_code"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.GATSBY_GA_ID}');`,
          }}
        />
      );
    case 'hub_spot':
      return (
        <script
          type="text/javascript"
          id="hs-script-loader"
          key="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/8735614.js"
        ></script>
      );
    case 'fb_code':
      return (
        <script
          id="fb_code"
          key="fb_code"
          dangerouslySetInnerHTML={{
            __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('consent', 'revoke');
          fbq('init', '${process.env.GATSBY_FB_PIXEL}');
          fbq('track', 'PageView');`,
          }}
        />
      );
    case 'fb_code_ns':
      return (
        <noscript
          id="fb_code_ns"
          key="fb_code_ns"
          dangerouslySetInnerHTML={{
            __html: `
              <img height="1" width="1" style="display:none" 
              src="https://www.facebook.com/tr?id=${process.env.GATSBY_FB_PIXEL}&ev=PageView&noscript=1"/>`,
          }}
        />
      );
    default:
      return null;
  }
};
