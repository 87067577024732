/* eslint-disable no-nested-ternary */
// KEEP reset.css IMPORT ON TOP!
import './src/styles/reset.css';
//
import './src/styles/global.scss';
import './src/styles/khInterference.scss';
import './src/styles/suisse.scss';

import PropTypes from 'prop-types';
import React from 'react';

// import { TooltipContextProvider } from './src/context/TooltipContext';
import Root from './src/components/Root';
import { checkTrackingConsent, trackPageView } from './src/utils/Tracking';

export const wrapRootElement = ({ element }) => <Root>{element}</Root>;

wrapRootElement.propTypes = {
  element: PropTypes.any,
};

export const onRouteUpdate = ({ location }) => {
  checkTrackingConsent();
  trackPageView(location.pathname);
};

/*
 * FIXME or REMOVEME!
 *
 * This code adds a delay when navigation to a new
 * page. During this delay the scroll position of the
 * previous page is kept, and then after the delay
 * the page scrolls to the top.
 *
 * I think this is in some way related to doing fade-ins
 * and similar transitions, but it is code that is very
 * hard to maintain so it's disabled for now, and if
 * we don't find any other regressions, let's remove
 * it completely.
 */
/*
const getLatestSavedScrollPosition = (pathname, key) => {
  const n = sessionStorage.length;
  const partialKey = `@@scroll|${pathname}|${key}`;
  const results = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < n; i++) {
    const sessionkey = sessionStorage.key(i);
    if (sessionkey.includes(partialKey)) {
      results.push(sessionkey);
    }
  }

  if (results.length === 0) return 0;
  results.sort();
  return sessionStorage.getItem(results[results.length - 1]);
};

const transitionDelay = 650; // 300
export const shouldUpdateScroll = ({
  routerProps: { location },
  // getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    // const savedPosition = getSavedScrollPosition(location, location.key);
    const savedPosition = getLatestSavedScrollPosition(
      location.pathname,
      location.key
    );
    window.setTimeout(
      () => window.scrollTo(...([0, savedPosition] || [0, 0])),
      transitionDelay
    );
  }
  return false;
};
*/

export const onClientEntry = () => {
  if ('scrollRestoration' in window.history)
    window.history.scrollRestoration = 'manual';
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty(
    '--scrollbar-width',
    `${scrollbarWidth}px`
  );

  const ua = navigator.userAgent.toLowerCase();
  const platform = navigator.platform.toLowerCase();
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

  const isAndroidBrowser = ua.indexOf('android') !== -1 && isTouchDevice;
  const isSafariBrowser = ua.indexOf('safari') !== -1;
  const isSamsungBrowser =
    ua.indexOf('samsungbrowser') !== -1 && isAndroidBrowser;

  const doc = document.documentElement;

  if (platform.indexOf('iphone') !== -1 && isSafariBrowser) {
    const wd = window.screen.height - window.innerHeight;
    const val = wd > 120 ? (wd > 171 ? 114 : 26) : wd > 108 ? 75 : 26;
    doc.style.setProperty('--app-height-offset', `${val}px`);
  }

  // if (platform.indexOf('ipad') !== -1 && isSafariBrowser) {
  //   const val = ua.indexOf('crios') !== -1 ? 69 : 31;
  //   doc.style.setProperty('--app-height-offset', `${val}px`);
  // }

  if (isAndroidBrowser && ua.indexOf('chrome') !== -1) {
    const val = 55;
    doc.style.setProperty('--app-height-offset', `${val}px`);
  }

  if (isAndroidBrowser && isSamsungBrowser) {
    const val = window.innerHeight;
    doc.style.setProperty('--app-height-offset', `0px`);
    doc.style.setProperty('--app-height', `${val}px`);
  }
};
